import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import './css/styles.css';
export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "image-gallery-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <body>
    <div className="gallery">
        <a href="/gallery-pages/patio-description"><img src="/files/patio.png" alt="Image 1"></img></a>
        <a href="/gallery-pages/pool-description"><img src="/files/pool.png" alt="Image 1"></img></a>
        <a href="/gallery-pages/pool-sunset-description"><img src="/files/pool2.png" alt="Image 1"></img></a>
    </div>
    <div className="gallery">
        <a href="/gallery-pages/donkeys-outside-samara-adventure-company"><img src="/files/image_2022_12_03T18_11_29_211Z.png" alt="Image 1"></img></a>
        <a href="/gallery-pages/crab-description"><img src="/files/image_2022_12_03T18_11_11_169Z.png" alt="Image"></img></a>
        <a href="/gallery-pages/beach-sunset-description"><img src="/files/20230213_174450.jpg" alt="Image"></img></a>
    </div>
    <div className="gallery">
        <a href="/gallery-pages/beach-sunset-with-palm-tree-description"><img src="/files/20230214_181509.jpg" alt="Image 1"></img></a>
        <a href="/gallery-pages/vintage-phone-description"><img src="/files/image_2022_12_03T18_14_32_549Z.png" alt="Image"></img></a>
        <a href="/gallery-pages/a-tranquil-place-in-liberia"><img src="/files/image_2022_12_03T18_14_41_610Z.png" alt="Image"></img></a>
    </div>
    <div className="gallery">
        <a href="/gallery-pages/catarata-waterfall-description"><img src="/files/image_2022_12_03T18_14_51_946Z.png" alt="Image 1"></img></a>
        <a href="/gallery-pages/fish-in-catarata-waterfall-description"><img src="/files/image_2022_12_03T18_15_02_089Z.png" alt="Image"></img></a>
        <a href="/gallery-pages/the-president-and-his-wife"><img src="/files/image_2022_12_03T18_15_15_171Z.png" alt="Image"></img></a>
    </div>
   
    </body>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      